// 评审会问题及落实
<template>
  <div>
      <base-table
        :columns="reviewMeetingConfig"
        :tableAttrs="{
          data: meetingSummaryDetailVOList,
          stripe: true,
        }"
        class="main-page-table"
        :isCaculateHeight="false"
        :showPage="false"
      >
      <template slot="index" slot-scope="scope">{{scope.$index+1}} </template>
      <template slot="description" slot-scope="scope">
        <base-input v-model="scope.row.description" maxlength='2000' placeholder="请选择还款合同"></base-input>
      </template>
      <template slot="situation" slot-scope="scope">
        <base-input v-model="scope.row.situation" maxlength='2000' placeholder="请选择还款合同"></base-input>
      </template>
      </base-table>
  </div>
</template>

<script>
import BaseInput from '@/components/input/sz-input/sz-input.vue'
import { reviewMeetingConfig } from '../utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
export default {
  components: { BaseTable, BaseInput },
  props: {
    meetingSummaryDetailVOList: Array
  },
  data () {
    return {
      tableData: [{}, {}]
    }
  },
  // 计算属性 类似于data概念
  computed: {
    reviewMeetingConfig () {
      return reviewMeetingConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped></style>
